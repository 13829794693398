<template>
  <div v-loading="loader" class="px-2 pt-4 pb-4">
    <div class="row m-0 pl-3 pb-2">
      <h1 class="page-title">Our Website</h1>
    </div>
    <div class="row m-0">
      <div class="col-12">
        <url-card ref="urlCardForm"
                  :url="form.url"
                  :status="form.status"
                  :url-required="true"/>
      </div>
    </div>
    <el-button class="ml-3 px-5 btn-primary-action font-weight-bold" @click="handleClick">Update</el-button>
  </div>
</template>
<script>
import UrlCard from "../../Components/ContentSection/UrlCard";

export default {
  name: "OurWebsite",
  components: {UrlCard},
  data() {
    return {
      form: {
        url: '',
        status: '1'
      },
      loader: false
    }
  },
  methods: {
    handleClick() {
      if (this.$refs.urlCardForm.formValidate()) {
        const formData = this.$refs.urlCardForm.form
        this.$emit('formSubmit', formData)
        this.createRequest()
      }
    },
    fetchRequest() {
      this.loader = true
      axios.get(`${this.$store.getters.getBaseUrl}/api/content/our-website`)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.form = response.data.data
        })
        .catch((error) => {console.log(error.response.statusText)})
        .finally(() => this.loader = false)
    },
    createRequest() {
      this.loader = true
      const formData = this.$refs.urlCardForm.form
      axios.post(`${this.$store.getters.getBaseUrl}/api/content/our-website/create`, formData)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$notify.success({
            title: 'Success',
            message: 'Updated successfully. Always check to see how your changes look in the Baritastic app.'
          })
        })
        .catch(() => {
          this.$notify.error({
            title: 'Failure',
            message: 'Something went wrong Please try again later'
          })
        })
        .finally(() => this.loader = false)
    }
  },
  mounted() {
    this.fetchRequest()
  }
}

</script>

<style scoped>

.page-title {
  font: normal normal 600 25px/34px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
}

</style>
